/* Header */

.t-header {
    display: flex;
    height: $header-height;
    background: $header-bg;
    z-index: $header-zindex;

    @include media-breakpoint-down(md) {
        padding-left: 5px;
        padding-right: 5px;
    }

    .t-header-brand-wrapper {
        display: flex;
        align-items: center;
        height: $header-height;
        width: $sidebar-width;
        min-width: $sidebar-width;
        max-width: $sidebar-width;
        background: $brand-header-bg;
        z-index: $header-zindex;
        padding-left: 18px;

        a {
            display: flex;
            align-items: center;
            color: theme-color(light);
            font-weight: 500;
            @include font-size(20);

            .logo {
                max-width: 100%;
                width: $logo-width;
            }

            .logo-mini {
                display: none;
                max-width: 100%;
                width: $logo-mini-width;
            }

            p {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            padding-left: 0;
            justify-content: center;

            a {
                .logo-mini {
                    width: 25px;
                }
            }
        }
    }

    .t-header-content-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        padding: 0 $body-margin-x;

        @include media-breakpoint-down(md) {
            padding: 0 1rem;
        }

        .t-header-search-box {
            display: flex;
            width: 20%;
            height: 40px;
            background: $card-bg;
            border-radius: 50px;
            padding: 5px;
            transition: 0.3s ease-in-out;
            transition-property: "width";
            overflow: hidden;

            @media (max-width: 580px) {
                display: none;
            }

            .form-control {
                height: inherit;
                border: none;
                background: transparent;
                @include font-size(16);
                font-weight: 500;
                padding-left: 0;
                padding: 5px 20px;

                @include input-placeholder {
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                }
            }

            button[type="submit"] {
                height: 100%;
                border-radius: 50px;
                padding: 0 7px;
                box-shadow: 0px 0px 5px -1px theme-color(primary);
                transition-duration: 0.3s;
                transition-property: "box-shadow";

                i {
                    font-size: 15px;
                }

                &:hover {
                    box-shadow: 0px 0px 8px -1px theme-color(primary);
                }
            }
        }

        .t-header-content {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 100%;

            .nav {
                .nav-item {
                    .nav-link {
                        position: relative;

                        i {
                            color: $sidebar-link-color;
                        }

                        .notification-indicator {
                            position: absolute;
                            top: 12px;
                            right: 12px;
                        }
                    }

                    &:last-child {
                        .nav-link {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    .t-header-toggler {
        background: transparent;
        border: none;
        margin-left: auto;

        i {
            @include font-size(22);
        }

        &.t-header-mobile-toggler {
            margin-left: 0;
            margin-right: 15px;
        }
    }

    &.fixed-top {
        position: fixed;
    }
}

.header-fixed {
    .t-header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        z-index: $header-zindex;
    }

    .sidebar {
        padding-top: $header-height;

        .t-header-brand-wrapper {
            position: fixed;
            left: 0;
            top: 0;
            z-index: $header-zindex;
            width: $sidebar-width;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
        }

        .navigation-menu {
            z-index: 1;
        }
    }
}